import React, { useState, useEffect, useRef} from "react";
import "./subpage.scss";

import Menu from '../CoreComponents/Menu/Menu'
import Header from '../CoreComponents/Header/Header'
import { RATIONAL_RESET, VIVA_IRL, EDITOR_LETTER, SELF_STASIS } from '../../lib/constants';
import SubPageMain from './../CoreComponents/SubPageSections/SubPageMain'
import SubPageBody from './../CoreComponents/SubPageSections/SubPageBody'
import SubPageEditorsLetterBody from './../CoreComponents/SubPageSections/SubPageEditorsLetterBody'

import logo from './../../assets/core/logo.svg'
// import hero from "./../../assets/subpage/"

// This is the SubPage Component for each Pillar

const SubPage = ({ data, open, onClick, setIsNavBarHidden, isNavBarHidden }) => {

  const subPageClassName = `${(data.toLowerCase())}`
  const subPageRef = useRef()

  /* HACK. SUCH SHAME. BIG SAD*/
  let getSubPage = "";

  const subPageData = data.toUpperCase().replace(/-/g, '_');

  switch (subPageData) {
    case "RATIONAL_RESET":
      getSubPage = RATIONAL_RESET
      break;
    case "SELF_STASIS":
      getSubPage = SELF_STASIS
      break;
    case "VIVA_IRL":
      getSubPage = VIVA_IRL
      break;
    case "EDITOR_LETTER":
      getSubPage = EDITOR_LETTER
      break;
    default:
      getSubPage = EDITOR_LETTER;
  }

  /* END HACK */

  // useEffect (() => {
  //   const preloadImages = () =>{
  //     const images = ["./../../assets/subpage/el_hero.png","./../../assets/subpage/rr_hero.png","./../../assets/subpage/ss_hero.png","./../../assets/subpage/vi_hero.png"];
  //     const prelaodedImages = [];
      
  //     for(let i=0; i<images.length; i++){   
  //       prelaodedImages[i] = new Image();
  //       prelaodedImages[i].src = require(images[i]);
  //     }
      
  //     }
      
  //     preloadImages();
  // })

  useEffect(()=> {
    const hideNav = (e) => {
      const bodyPositionFromTop = 482
      const scrollTopPosition = e.target.scrollTop

      setIsNavBarHidden(scrollTopPosition > bodyPositionFromTop)
    }
    subPageRef.current.addEventListener("scroll", hideNav)
    return(()=>{
      subPageRef.current.removeEventListener("scroll", hideNav)
    })

  })

  const handleBackToTop = () =>{
    subPageRef.current.scrollTo({top: 0,left: 0,behavior: 'smooth'});
  }

  const subSectionContent = () => {

    return (
      <div className="subpage-inner">
        {Object.keys(getSubPage).map((currentSection, i) => (
          <div key={i} className={`outer-grid-wrapper ${currentSection.toLowerCase()}`}>
            {(() => {
              if (currentSection === "SECTION_1") {
                const { TITLE_1, TITLE_2, HERO, SUBTITLE, SUBTITLE_2, CALLOUT_IMG, CALLOUT_IMG2, CALLOUT_HEADER, CALLOUT_HEADER2, CALLOUT_BLURB, CALLOUT2_BLURB, CONTENT_1, CONTENT_2, CONTENT_3 } = getSubPage[currentSection]
                return (
                  <>
                    <div className="subpage__hero">
                      <img src={require(`./../../assets/subpage/${HERO}`)} alt={""} />
                    </div>
                    <button onClick={onClick}>Close</button>
                    <section>
                      <h2>{TITLE_1} <span>{TITLE_2}</span></h2>
                      <div className="subpage__subtitle">{SUBTITLE}</div>
                      {(SUBTITLE_2) ? <div className="subpage__subtitle2">{SUBTITLE_2}</div> : ""}
                      <div dangerouslySetInnerHTML={{ __html: CONTENT_1 }} />
                      <div className="subpage__callout">
                        <h3>
                          <img src={require(`./../../assets/subpage/${CALLOUT_IMG}`)} alt={""} />
                          {CALLOUT_HEADER}
                          <span>{CALLOUT_HEADER2}</span>
                        </h3>
                        {/* <p>{CALLOUT_BLURB}</p> */}
                        <p dangerouslySetInnerHTML={{ __html: CALLOUT_BLURB }} />
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: CONTENT_2 }} />
                      {(CONTENT_3) ? (<>
                        <div className="subpage__callout2">
                          <img src={require(`./../../assets/subpage/${CALLOUT_IMG2}`)} alt={""} />
                          <p>{CALLOUT2_BLURB}</p>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: CONTENT_3 }} />
                        {(getSubPage == EDITOR_LETTER) ? <img className="subpage__el-logo" src={logo} /> : ""}
                      </>) : ""}
                    </section>
                  </>
                );
              } else {
                if (getSubPage == EDITOR_LETTER) {
                  return "";
                } else {
                  return <SubPageBody data={[getSubPage[currentSection]]}></SubPageBody>;
                }
              }
            })()}
          </div>
        ))}
        {(isNavBarHidden) ? <button className="back-to-top" onClick={handleBackToTop}>Back to Top</button> : ""}
      </div>
    )
  }

  return (
    <main ref={subPageRef} className={`subpage ${subPageClassName} ${open}`}>
      {subSectionContent()}
    </main>
  );

};

export default SubPage;



{/* <p>{()=>foo()}</p> */ }
