import React,{useEffect} from 'react';
import { useInView } from 'react-intersection-observer';
import { HOME } from '../../lib/constants';
import './pillar.scss';
import wtf from './../../assets/pillars/wtf.png'
import { useNavigate } from 'react-router-dom';
import Socials from './../CoreComponents/Socials/Socials'

// These are the individual slides for the Home DESKTOP PAGES ONLY

const Pillar = ({ data, onClick, playAnimation, isAnimated}) => {

    const nav = useNavigate()

    // This is title and create a custon CSS Class name. ie "self-stasis"
    const pillarClassName = `${(data.TITLE_1.replace("'", ""))}${data.TITLE_2 ? "-" + data.TITLE_2 : ""}`

    const [ pillarRef, inView, entry ] = useInView({
        /* Optional options */
        threshold: .9,
      });


    useEffect(()=>{
        if(inView) {
            nav(`#${pillarClassName.toLowerCase()}`, { replace: false })
            playAnimation(inView, entry)
        }
    },[inView])

    return (
        <div ref={pillarRef} className={`pillar ${pillarClassName.toLowerCase()}`} id={pillarClassName.toLowerCase()}>

            <div className="pillar__midground"></div>
            <div className='animation-sun'></div>
            <div className={`animation-camel ${(isAnimated.ss)? "walk" : "" }`}></div>
            <div className='animation-bush'></div>
            <div className='animation-xwoman'></div>
            <div className='animation-eye'></div>
            <div className="animation-flicker"></div>
            <div className="animation-butterfly1"></div>
            <div className="animation-butterfly2"></div>
            <div className={`animation-rocket ${(isAnimated.vi)? "fly" : ""}`}></div>
            <div className="pillar__bar">
                <div className="pillar__text">
                    
                    {( data.TITLE_1 === "Share" ) ? (<>
                        <h2 className='Share__title'>{data.SUBTITLE}</h2>
                        <Socials></Socials>
                        <a className="share__contact" href={data.LINK.URL} target="_blank">{data.LINK.CONTACT_US}</a>
                    </>)
                    : (<>
                        <h2><span>{data.TITLE_1}</span> {data.TITLE_2}</h2>
                        <button onClick={()=>onClick(pillarClassName)}>{HOME.LEARN_MORE}</button>
                    </>)}
                    
                </div>
                
            </div>

        </div>
    );
};

export default Pillar;
