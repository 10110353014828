import React, { useState, useEffect, useRef } from "react";
import "./home.scss";

import Menu from "./../CoreComponents/Menu/Menu";
import Header from "./../CoreComponents/Header/Header";
import Pillar from "../Pillar/Pillar";
import SubPage from "../SubPage/SubPage";
import HomeMobile from "../HomeMobile/HomeMobile";
import { PILLARS } from "../../lib/constants";
import IntroVideo from "../IntroVideo/IntroVideo";

const Home = () => {
	// Home State
	const [isSubPageOpen, setIsSubPageOpen] = useState(false);
	const [pillarActive, setPillarActive] = useState("");
	const [animate_ss, setanimate_ss] = useState(false);
	const [animate_rr, setanimate_rr] = useState(false);
	const [animate_vi, setanimate_vi] = useState(false);
	const [isNavBarHidden, setIsNavBarHidden] = useState(false);
	const [isHomeReady, setIsHomeReady] = useState(false);
	const [slideSelected, setSlideSlected] = useState(0);
	const open = isSubPageOpen ? "open" : "";

	const homeIsReady = () => setIsHomeReady(true);
	const handleSelected = (index) => setSlideSlected(index);

	// Menu Handlers
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const handleOpenMenu = () => setIsMenuOpen(true);
	const handleCloseMenu = () => {
		setIsMenuOpen(false);
		// const scrollContainer = document.querySelector(".scrollContainer");
		//   scrollContainer.wheel = 1
		//   console.log(scrollContainer.wheel);
	};

	useEffect(() => {
		const scrollContainer = document.querySelector(".scrollContainer");
		const menuItems = document.querySelectorAll(".menu.open ul a");
		const el_El = document.querySelector(".pillar.editors-letter");
		const ss_El = document.querySelector(
			".pillar.self-stasis .pillar__midground"
		);
		const sun = document.querySelector(
			".pillar.self-stasis .animation-sun"
		);
		const rr_El = document.querySelector(".pillar.rational-reset");
		const xwoman = document.querySelector(
			".pillar.rational-reset .animation-xwoman"
		);
		const eye = document.querySelector(
			".pillar.rational-reset .animation-eye"
		);
		const vi_El = document.querySelector(
			".pillar.viva-irl .pillar__midground"
		);
		const butterfly1 = document.querySelector(
			".pillar.viva-irl .animation-butterfly1"
		);
		const butterfly2 = document.querySelector(
			".pillar.viva-irl .animation-butterfly2"
		);
		const rocket = document.querySelector(
			".pillar.viva-irl .animation-rocket"
		);
		const titleEls = document.querySelectorAll(".pillar__text");
		const pillarCount = Object.keys(PILLARS).length;
		let position = { left: 0, x: 0 };

		const parallaxEffect = (e) => {
			if (e.type !== "touchmove") e.preventDefault();

			if (e.type == "wheel") {
				scrollContainer.scrollLeft += 0.07 * e.deltaY;
			} else if (e.type == "touchmove") {
				const dx = e.targetTouches[0].clientX - position.x;
				scrollContainer.scrollLeft = position.left - dx;
			} else {
				const dx = e.clientX - position.x;
				scrollContainer.scrollLeft = position.left - dx;
			}

			const percentage =
				(scrollContainer.scrollLeft / scrollContainer.scrollWidth) *
				100;

			// This if for the Parallax Effect for the collages on Desktop
			el_El.style.backgroundPosition = `
      calc(70% + 5vw) center, 
      calc(${percentage * -5 + 330}%) calc(50%)
    `;

			ss_El.style.backgroundPosition = `
      calc(${percentage * -5 + 140}%) calc(0% + 18vh),
      calc(${percentage * -3 + 65}%) calc(50% + 0vh)
    `;

			sun.style.marginLeft = `${percentage * -1 + 73}%`;

			rr_El.style.backgroundPosition = `
        calc(${percentage * -5 + 210}%) calc(50% + 8vh), 
        calc(${percentage * -3 + 110}%) calc(90% + 8vh), 
        calc(50% + -22vw) center  
      `;
			xwoman.style.marginLeft = `${percentage * -5 + 243}%`;
			eye.style.marginLeft = `${percentage * -5 + 230}%`;

			vi_El.style.backgroundPosition = `
      calc(${percentage * -5 + 327}%) calc(90% + 14vh), 
      calc(${percentage * -3 + 179}%) calc(100% + 14vh)
      `;
			rocket.style.marginLeft = `${percentage * -2.5 + 320}%`;
			butterfly1.style.marginLeft = `${percentage * -3 + 194}%`;
			butterfly2.style.marginLeft = `${percentage * -3 + 209}%`;

			// This if for the Parallax Effect for the Titles on Desktop
			for (let [i, title] of titleEls.entries()) {
				title.style.transform = `translate3d(${scrollContainer.scrollLeft / -pillarCount
					}px, 0, 0)`;
			}
		};

		const mouseDownHandler = (e) => {
			const clientX = e.clientX || e.targetTouches[0].clientX;

			position = {
				left: scrollContainer.scrollLeft,
				x: clientX,
			};
			document.addEventListener("mousemove", parallaxEffect);
			document.addEventListener("touchmove", parallaxEffect);
			document.addEventListener("mouseup", mouseUpHandler);
			document.addEventListener("touchend", mouseUpHandler);
		};

		const mouseUpHandler = () => {
			document.removeEventListener("mousemove", parallaxEffect);
			document.removeEventListener("touchmove", parallaxEffect);
			document.removeEventListener("mouseup", mouseUpHandler);
			document.removeEventListener("touchend", mouseUpHandler);

			// scrollContainer.style.cursor = "grab";
			scrollContainer.style.removeProperty("user-select");
		};

		scrollContainer.addEventListener("wheel", parallaxEffect);
		scrollContainer.addEventListener("mousedown", mouseDownHandler);
		scrollContainer.addEventListener("touchstart", mouseDownHandler);
		for (let item of [...menuItems]) {
			item.addEventListener("wheel", mouseDownHandler);
		}
	});

	const goToSubPage = (pillar) => {
		setPillarActive(pillar);
		setIsSubPageOpen(!isSubPageOpen);
	};
	const closeSubPage = () => setIsSubPageOpen(false);

	// Trigger Aninmaion in View
	const handleAnimation = (inview, entry) => {
		const ss = document.querySelector(".pillar.self-stasis");
		const rr = document.querySelector(".pillar.rational-reset");
		const vi = document.querySelector(".pillar.viva-irl");
		const target = entry && entry.target;

		setanimate_ss(inview && target == ss);
		setanimate_rr(inview && target == rr);
		setanimate_vi(inview && target == vi);
	};

	const Pillars = Object.keys(PILLARS).map((slide, i) => {
		return (
			<Pillar
				key={i}
				data={PILLARS[slide]}
				onClick={(pillar) => goToSubPage(pillar)}
				playAnimation={(inview, entry) =>
					handleAnimation(inview, entry)
				}
				isAnimated={{ ss: animate_ss, rr: animate_rr, vi: animate_vi }}
			/>
		);
	});

	return (
		<div className="home-container">
			<IntroVideo delay={5000} setIsHomeReady={() => homeIsReady()} />
			<main className={`home ${!isHomeReady ? "hide" : ""}`}>
				<Menu
					isOpen={isMenuOpen}
					closeMenu={handleCloseMenu}
					setSlide={handleSelected}
					closeSubPage={() => closeSubPage()}
					isSubPageOpen={isSubPageOpen}
				/>
				<Header
					onClick={handleOpenMenu}
					isSubPageOpen={isSubPageOpen}
					isNavBarHidden={isNavBarHidden}
					setSlide={handleSelected}
					closeSubPage={() => closeSubPage()}
					setIsNavBarHidden={setIsNavBarHidden}
				/>
				<div className="scrollContainer">{Pillars}</div>
				<SubPage
					open={open}
					data={pillarActive}
					onClick={() => closeSubPage()}
					setIsNavBarHidden={setIsNavBarHidden}
					isNavBarHidden={isNavBarHidden}
				/>
			</main>
			<HomeMobile
				onClick={(pillar) => goToSubPage(pillar)}
				open={open}
				data={pillarActive}
				closeSubPage={() => closeSubPage()}
				handleOpenMenu={handleOpenMenu}
				isMenuOpen={isMenuOpen}
				handleCloseMenu={handleCloseMenu}
				setIsNavBarHidden={setIsNavBarHidden}
				isNavBarHidden={isNavBarHidden}
				slideSelected={slideSelected}
				setSlide={handleSelected}
				isHomeReady={isHomeReady}
				isSubPageOpen={isSubPageOpen}
			/>
		</div>
	);
};

export default Home;
