import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./home-mobile.scss";
// requires a loader
import { Carousel } from "react-responsive-carousel";
import { PILLARS, HOME } from "../../lib/constants";
import Socials from "../CoreComponents/Socials/Socials";
import SubPage from "../SubPage/SubPage";
import Header from "../CoreComponents/Header/Header";
import Menu from "../CoreComponents/Menu/Menu";
import { useEffect } from "react/cjs/react.development";

// This is the Carousel for the Home MOBILE PAGES ONLY

const HomeMobile = ({
	onClick,
	open,
	closeSubPage,
	handleOpenMenu,
	handleCloseMenu,
	isMenuOpen,
	isNavBarHidden,
	setIsNavBarHidden,
	slideSelected,
	setSlide,
	isHomeReady,
	isSubPageOpen,
	data
}) => {
	const [isShareInView, setIsShareInView] = useState(false);
	// const [data, setData] = useState("Editors-Letter");

	const Slides = Object.keys(PILLARS).map((slide, i) => {
		const pillarClassName = `${PILLARS[slide].TITLE_1.replace("'", "")}${
			PILLARS[slide].TITLE_2 ? "-" + PILLARS[slide].TITLE_2 : ""
		}`;

		return (
			<section
				key={i}
				className={`pillar-mobile ${pillarClassName.toLowerCase()}`}
			>
				<div className={`pillar-mobile__body`}>
					{PILLARS[slide].TITLE_1 == "Share" ? (
						<>
							<h2 className="share__title">
								{PILLARS[slide].SUBTITLE}
							</h2>
							<Socials></Socials>
							<a
								className="share__contact"
								href={PILLARS[slide].LINK.URL}
								target="_blank"
							>
								{PILLARS[slide].LINK.CONTACT_US}
							</a>
						</>
					) : (
						<>
							<h2>
								<span className="word-1">
									{PILLARS[slide].TITLE_1}
								</span>{" "}
								<span className="word-2">
									{PILLARS[slide].TITLE_2}
								</span>
							</h2>
							<button onClick={() => onClick(pillarClassName)}>
								{HOME.LEARN_MORE}
							</button>
						</>
					)}
					{/* <Socials /> */}
				</div>
			</section>
		);
	});

	const handleCarouselChange = (e) => {
		// const sharePageNumber = 4
		e === 4 ? setIsShareInView(true) : setIsShareInView(false);
	};

	return (
		<main
			className={`home-mobile ${isShareInView ? "white" : ""} ${
				!isHomeReady ? "hide" : ""
			}`}
		>
			<Menu
				isOpen={isMenuOpen}
				closeMenu={handleCloseMenu}
				setSlide={setSlide}
				isSubPageOpen={isSubPageOpen}
			/>
			<Header
				onClick={handleOpenMenu}
				isNavBarHidden={isNavBarHidden}
				closeSubPage={closeSubPage}
				setSlide={setSlide}
				isSubPageOpen={isSubPageOpen}
				setIsNavBarHidden={setIsNavBarHidden}
			/>
			<Carousel
				showStatus={false}
				showThumbs={false}
				onChange={(e) => handleCarouselChange(e)}
				selectedItem={slideSelected}
			>
				{Slides}
			</Carousel>
			<SubPage
				open={open}
				data={data}
				onClick={closeSubPage}
				setIsNavBarHidden={setIsNavBarHidden}
				isNavBarHidden={isNavBarHidden}
				isSubPageOpen={isSubPageOpen}
			/>
		</main>
	);
};

export default HomeMobile;
