import './App.css';
import React, {useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import axios from "axios"

import Login from './components/Login/Login'
import Home from './components/Home/Home'
import SubPage from './components/SubPage/SubPage'
import IntroVideo from "./components/IntroVideo/IntroVideo";
const testData = ['jamie.jarvis@makemarks.com', 'aaron.hutchings@makemarks.com']

function App() {
  const [token, setToken] = useState(window.localStorage.getItem('EmailAdded-2023'));

  useEffect(()=> {
    ReactGA.initialize("G-H7ZXH4CYJB");
  },[])

  const handleCheckEmail = (email) => {
    if(true){
      setToken(true)
      window.localStorage.setItem("EmailAdded-2023", true)
    } else {
      alert("Sorry didn't work")
    }
  }

  const handleEmailPost = (body) => {
    axios.post("/post", body)
    .then(response => console.log(response.data.id))
    .catch((err) =>{
      console.log("UH OH",err)
    })
    setToken(true)
    window.localStorage.setItem("EmailAdded-2023", true)

    ReactGA.event({
      category: "Pre-Register Form",
      action: "Completed",
      label: "User added to DB", // optional
      transport: "xhr", // optional, beacon/xhr/image
    });
  }

  if(!token && window.location.pathname !== "/2022") {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Trends 2022 Visited" });
    return <Login token={token} setToken={setToken} validateEmail={handleCheckEmail} postEmail={handleEmailPost} />
  }

  return (
    <Router className="App">
      <Routes>
        <Route path='/' element={<Login token={token} setToken={setToken} validateEmail={handleCheckEmail} postEmail={handleEmailPost} />}/>
        <Route path="/2022" element={<Home token={token} setToken={setToken} validateEmail={handleCheckEmail} postEmail={handleEmailPost} />} />
      </Routes>
    </Router>
  );
}

export default App;
