import React, { useState } from 'react'
import './login.scss'
import Logo from './../../assets/login/logo.svg'
import { LOGIN } from './../../lib/constants'



const Login = ({ validateEmail, postEmail, setToken, token}) => {
    const [emailAddress, setEmailAddress] = useState("");
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [company, setCompany] = useState("");
    const [acceptContact, setAcceptContact] = useState(false);
    const [acceptAddress, setAcceptTerms] = useState(false);
    // const [isSignUp, setIsSignUp] = useState(false)
    
    // const handleSubmit = e => {
    //     e.preventDefault();
    // }
    // const toggleSignUp = () => setIsSignUp(!isSignUp)

  const submitEmail = async(e) => {
    e.preventDefault();

    if (!emailAddress || !name || !title || !company) {
      alert("Please Fill Entire Form")
    } else if (!acceptAddress) {
      alert("Must agree to terms")
    } else {
      const member_detail = {
        EMAIL: emailAddress,
        NAME: name,
        TITLE: title,
        COMPANY: company,
        LEAD: (acceptContact) ? "Yes" : "No"
      }
      // postEmail(member_detail)
      if(emailAddress){
        await fetch(`/api/addMember?email=${emailAddress}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email_address: emailAddress,
            merge_fields: member_detail
          })
        })
        .then(resp => resp.json())
        .then(data => {
          if(data.statusCode === 200) setToken(true)}
          )
        .catch(err => console.log(err))
      }
    }
  }

    const handleEmail = e => setEmailAddress(e.target.value)
    const handleName = e => setName(e.target.value)
    const handleTitle = e => setTitle(e.target.value)
    const handleCompany = e => setCompany(e.target.value)
    const handleAcceptContact = e => setAcceptContact(e.target.checked)
    const handleAcceptTerms = e => setAcceptTerms(e.target.checked)

    // const listBenefits = LOGIN.TEASER_LIST.map((item, i) => {
    //   return (
    //     <li key={i}>{item}</li>
    //   )
    // })

    return (
        <main className='login'>
            <h1>Login</h1>
            {(!token) ?
            <section>   
                <div>
                    <img src={Logo} alt='Marks logo'/>
                    <h2>{LOGIN.CTA_COPY}</h2>
                    <h3>{LOGIN.CTA_COPY_2}</h3>
                    {/* <ul>
                      {listBenefits}
                    </ul> */}
                    <p>{LOGIN.TEASER}</p>
                </div>      
                <form onSubmit={ submitEmail }>
                    <label htmlFor="email"></label>
                    <input className='login__input' name="email" id="email" type="email" onChange={ handleEmail } required={true} placeholder="Business Email Address*" />
                    <input className='login__input' name="name" id="name" type="text" onChange={ handleName } required={true} placeholder="Name*" />
                    <input className='login__input' name="title" id="title" type="text" onChange={ handleTitle } required={true} placeholder="Business Title*" />
                    <input className='login__input' name="company" id="company" type="text" onChange={ handleCompany } required={true} placeholder="Organization*" />
                    {/* <p>{LOGIN.SIGNUP_COPY}<a onClick={toggleSignUp}>{LOGIN.SIGNUP_LINK}</a></p> */}
                    <p className='legal' dangerouslySetInnerHTML={{__html: LOGIN.LEGAL }} />
                    <div>
                      <input id='accept-contact' type='checkbox' onChange={ handleAcceptContact } />
                        <label htmlFor='accept-contact'>{LOGIN.AGREE_CONTACT}</label>
                    </div>
                    <div>
                        <input id='accept' type='checkbox' onChange={ handleAcceptTerms } required={true} />
                        <label htmlFor='accept'>{LOGIN.AGREE}*</label>
                    </div>
                    <button type='submit' value="Subcribe" disabled={!emailAddress}>{LOGIN.CTA_BUTTON_SIGN_UP}</button>


                </form> 
            </section> : 
            <section>
              <div className='full'>
                <img src={Logo}  alt='Marks logo'/>
                <h2 className='thank-you'>{LOGIN.THANK_YOU_TITLE}</h2>
                <h3 className='thank-you'>{LOGIN.THANK_YOU}</h3>
              </div>
            </section>
            }
        </main>
    )
}

export default Login
