import React from 'react'
import './menu.scss'
import { MENU_LIST } from '../../../lib/constants'
import { useEffect } from 'react/cjs/react.development'
import Socials from './../Socials/Socials'

const Menu = ({isOpen, closeMenu, setSlide, closeSubPage, isSubPageOpen}) => {
    const open = (isOpen) ? "open" : ""

    // useEffect(()=> {
    //     const anchors = document.querySelectorAll('.home a[href^="#"]')
    //     if(anchors){
    //         anchors.forEach((anchor, i) => {
    //             anchor.addEventListener('click', function (e) {
    //                 e.preventDefault();
    //                 document.querySelector(this.getAttribute('href')).scrollIntoView({
    //                     behavior: 'smooth'
    //                 });
    //             });
    //         });
    //     }
    // })


    const handleMenu = (i) => {
        const wheelEvt = document.createEvent('MouseEvents');
        const scrollContainer = document.querySelector(".scrollContainer");
        const el_El = document.querySelector(".pillar.editors-letter");
        const ss_El = document.querySelector(".pillar.self-stasis .pillar__midground");
        const sun = document.querySelector(".pillar.self-stasis .animation-sun");
        const rr_El = document.querySelector(".pillar.rational-reset");
        const xwoman = document.querySelector(".pillar.rational-reset .animation-xwoman");
        const eye = document.querySelector(".pillar.rational-reset .animation-eye");
        const vi_El = document.querySelector(".pillar.viva-irl .pillar__midground");
        const butterfly1 = document.querySelector(".pillar.viva-irl .animation-butterfly1");
        const butterfly2 = document.querySelector(".pillar.viva-irl .animation-butterfly2");
        const rocket = document.querySelector(".pillar.viva-irl .animation-rocket");
        const titleEls = document.querySelectorAll(".pillar__text");

        wheelEvt.initEvent('wheel', true, true);
        wheelEvt.deltaY = ((i-1) * 0.2) * scrollContainer.scrollWidth
              

        const parallaxEffect = (e) => {
      
            //   scrollContainer.scrollLeft += 0.07 * e.deltaY
              scrollContainer.scrollLeft = ((i-1) * 0.2) * scrollContainer.scrollWidth

      
            const percentage =
              (scrollContainer.scrollLeft / scrollContainer.scrollWidth) *
              100;
      
            // This if for the Parallax Effect for the collages on Desktop
            el_El.style.backgroundPosition = `
            calc(70% + 5vw) center, 
            calc(${percentage * -5 + 330}%) calc(50%)
          `;
      
            ss_El.style.backgroundPosition = `
            calc(${percentage * -5 + 140}%) calc(0% + 18vh),
            calc(${percentage * -3 + 65}%) calc(50% + 0vh)
          `;
      
          sun.style.marginLeft = `${percentage * -1 + 73}%`;
          
            rr_El.style.backgroundPosition = `
              calc(${percentage * -5 + 210}%) calc(50% + 8vh), 
              calc(${percentage * -3 + 110}%) calc(90% + 8vh), 
              calc(50% + -22vw) center  
            `;
            xwoman.style.marginLeft = `${percentage * -5 + 243}%`;
            eye.style.marginLeft = `${percentage * -5 + 230}%`;
      
            vi_El.style.backgroundPosition = `
            calc(${percentage * -5 + 327}%) calc(90% + 14vh), 
            calc(${percentage * -3 + 179}%) calc(100% + 14vh)
            `;
            rocket.style.marginLeft = `${percentage * -2.5 + 320}%`;
            butterfly1.style.marginLeft = `${percentage * -3 + 194}%`;
            butterfly2.style.marginLeft = `${percentage * -3 + 209}%`;
      
      
            // This if for the Parallax Effect for the Titles on Desktop
            for (let [i, title] of titleEls.entries()) {
              title.style.transform = `translate3d(${scrollContainer.scrollLeft / -5
                }px, 0, 0)`;
            }
          };

          parallaxEffect()
        
        setSlide(i - 1)
        closeMenu()
        if(isSubPageOpen) closeSubPage()
    }

    const menuList = Object.keys(MENU_LIST).map((menuItem, i) => {
        return(<li key={i}><a onClick={()=>handleMenu(i)} >{MENU_LIST[menuItem].TITLE}</a></li>)
    })

    return (
        <div className={`menu ${open}`}>
            <button onClick={()=>closeMenu()}>Close</button>
            <div>
                <ul>
                    {menuList}
                </ul>
                <Socials />
            </div>

        </div>
    )
}

export default Menu
