import React, {useState, useEffect, useRef } from 'react';
import './introvideo.scss'
import videoSrc from './../../assets/video/WTF_ANI_1920x1080.mp4'

const IntroVideo = ({delay, setIsHomeReady}) => {
    const [isVisible, setIsVisible] = useState(true)
    const [isLeave, setIsLeave] = useState(false)
    const vidRef = useRef()

    useEffect(()=> {
        const x = window.matchMedia("(max-width: 1024px)")
        if (x.matches) setIsVisible(false); setIsHomeReady(true);
        const closevid = () => {
            setTimeout(()=>{
                setIsHomeReady(true)
            }, delay - 1000)
            setTimeout(()=>{
                setIsLeave(true)
            }, delay)
            setTimeout(()=>{
                setIsVisible(false)
            }, delay + 1000)
        }

        vidRef.current.addEventListener('ended', closevid )
        return () => {
            vidRef.current.removeEventListener('ended', closevid )
          };
    },[])
    

  return isVisible ? (
    <div className={`intro-vid ${(isLeave) ? "leave" : ""}`}>
        <video ref={vidRef} src={videoSrc} autoPlay={true} muted={true}></video>
    </div>
    ) : "";
};



export default IntroVideo;
