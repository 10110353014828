import React from 'react';
import facebook from './../../../assets/core/socials/facebook.svg'
import twitter from './../../../assets/core/socials/twitter.svg'
import instagram from './../../../assets/core/socials/instagram.svg'
import linkedin from './../../../assets/core/socials/linkedin.svg'

import './socials.scss'

const Socials = () => {

  //TODO: FIX ALIGNMENT

  return (
    <div className='socials'>
        {/* <a href='' target="_blank"><img src={facebook} alt='facebook' /></a>
        <a href='' target="_blank"><img src={twitter} alt='twitter' /></a> */}
        <div></div>
        <a href='https://www.instagram.com/marks.global/' target="_blank"><img src={instagram} alt='instagram' /></a>
        <a href='https://www.linkedin.com/company/markspartofsgs&co/' target="_blank"><img src={linkedin} alt='linkedin' /></a>
        <div></div>
    </div>
  );
};

export default Socials;
 