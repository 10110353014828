

// Menu Copy
export const MENU_LIST = {
    HOME: { TITLE: "Home", URL: "#editors-letter" },
    EDITOR_LETTER: { TITLE: "Editor's Letter", URL: "#editors-letter" },
    SELF_STASIS: { TITLE: "Self Stasis", URL: "#self-stasis" },
    RATIONAL_RESET: { TITLE: "Rational Reset", URL: "#rational-reset" },
    VIVA_IRL: { TITLE: "Viva IRL", URL: "#viva-irl" },
    SHARE: { TITLE: "Share", URL: "#share" }
}

//Login
export const LOGIN = {
    CTA_COPY: "INTERESTED IN TRENDS?",
    CTA_COPY_2: "Why Think Forward is the trend-forward thinkspace for Marks - a global creative agency at the collision of creativity, culture, and technology.",
    CTA_BUTTON_SIGN_IN: "Sign-in Now",
    CTA_BUTTON_SIGN_UP: "Sign-up Now",
    SIGNUP_COPY: "If you haven’t signed up for the trends report please ",
    SIGNUP_LINK: "sign up here",
    TEASER: "If you’d like to learn more about how cultural foresight and trendspotting can impact your business, or if you’re just keen to learn more about how trends work, fill out the form on the page.",
    TEASER_LIST: [
      "Invite-only events",
      "Global trend portal",
      "Bespoke private views"
    ],
    LEGAL: `<p>All registrants will be cross checked and qualified to ensure security and exclusivity.</p>
    <p>We’ll only contact you to say hi, when you register, invite you to our events, share access details for our trends portal, arrange private views, or take your feedback.</p>
    <p>To finalise your registration, please check the box below to consent to your data being collected and stored by Marks. For more details on how we’ll handle your data, please view our corporate <a href="https://www.sgsco.com/privacy-cookies">
    privacy policy</a>.</p>`,
    AGREE: "I agree to Marks privacy policy",
    AGREE_CONTACT: "I’d like someone from the Marks team to contact me about helping my business.",
    THANK_YOU_TITLE: "WELCOME TO THE WHY THINK FORWARD COMMUNITY", 
    THANK_YOU: `Thank you for sharing your information with us. If requested, a member of the team will be in touch soon to see how we can help you out.`
}


//Home Copy
export const HOME = {
    LEARN_MORE: "Learn More"
}

// Pillar Copy
export const PILLARS = {
    EDITOR_LETTER: {
        TITLE_1: "Editor's",
        TITLE_2: "Letter",
        SUBTITLE: "WTF: Why Think Forward",
        IMAGE: ""
    },
    SELF_STASIS: {
        TITLE_1: "Self",
        TITLE_2: "Stasis",
        SUBTITLE: "Shift from methods of practicing presence to a more humanistic approach to simply being",
        IMAGE: "",
    },
    RATIONAL_RESET: {
        TITLE_1: "Rational",
        TITLE_2: "Reset",
        SUBTITLE: "Shift from a of state existential crisis to the solace of structure, reason, and metaphysical truths",
        IMAGE: "",
    },
    VIVA_IRL: {
        TITLE_1: "Viva",
        TITLE_2: "IRL",
        SUBTITLE: "Shift from performative happiness to a yearning for visceral, human pleasure",
        IMAGE: "",
    },
    SHARE: {
        TITLE_1:"Share",
        SUBTITLE:"Share your love of Trends",
        LINK:{
            CONTACT_US: "Contact Us",
            URL: "https://www.makemarks.com/contact"
        }
    }
}


//SubPages
export const SELF_STASIS = {
    SECTION_1: {
        TITLE_1: "Stasis",
        TITLE_2: "Self",
        HERO: "ss_hero.png",
        SUBTITLE:"The shift from methods of practicing presence to a more humanistic approach to simply being.",
        CALLOUT_IMG: "ss_callout.png", 
        CALLOUT_HEADER: "Of Millennials", 
        CALLOUT_BLURB: "were experiencing burnout prepandemic, and they remain the most affected.",
        CONTENT_1:
        `
        <p>Perhaps the most “goopy,” pseudo-spiritual, overused word of the day is “mindfulness.” It’s the fashionable adjective that precedes the way we meditate, eat, breath, and, yes, even poop. Professor of Management at San Francisco State University and author of <i>McMindfullness</i>, Ron Purser, points to the wellness industry’s co-opting of the concept - separating it from its Buddhist roots - only to redeploy it as what he terms “a capitalist’s spirituality.” This buzzword is touted as a means of finding greater presence at a time when the present is one of our most anxiety-inducing constructs. For all its air time, mindfulness, as the Economist points out, is “useless in a pandemic.” <p/>

        <p>From what The Atlantic calls “the acceleration of The Great Resignation” to what author Tim Hwang calls “The Subprime Attention Crisis,” it seems many are finding modern meaning in Timothy Leary’s 1967 call to action (or non action), “Turn on, tune in, drop out”—refuting ethics of productivity, profit and purpose in favor of simply being human. <p/>
        
        <p>Resignation, dropping out, quitting, opting out—emotional health is taking priority. This attitude is neither a refusal of nor a retreat from the forces of today. Rather, it is an attempt to persist beyond our culture’s pervasive burnout by accepting the conditions of the day and choosing to exist outside them. </p>
        
        <p>In 2021, “niksen,” a Dutch term for doing nothing, joined the global lexicon. In January, Olga Mecking published her book on the subject following a 2019 New York Times article that went viral. This notion of doing nothing doesn’t always translate well to cultures that prize hard work and achievement, yet this shift is seeing more from those cultures willing to adapt the concept.</p>
        
        <p>Another manifestation of this idea of “opting out” appears in the form of the Buy Nothing Project. Members are encouraged to buy less and share more to benefit themselves, and the planet. Members offer up anything from baby equipment to expertise and get creative with bartering, gifting and trading. According to the non-profit’s website, there is growing interest in opting out of traditional economic models with 4.27M members across 44 countries. </p>
        `,
        CONTENT_2: `
        <p>Broadly, the stressors of our time have necessitated a significant shift in values, beliefs, and aspirations. A recent Indeed study found that 53% of Millennials were experiencing burnout pre-pandemic, and they remain the most affected. Globally, honoring the badge of being overworked is waning. In China, where their “996 work culture,” (9am to 9pm, six days a week) began to glean attention on the global stage, The Lying Flat Movement went viral after a manifesto of renunciation titled “Lying Flat Is Justice” was posted on the Baidu platform. The intent struck a chord with many workers facing ingrained, unsustainable work conditions. Not alone in the challenge to protect workers’ work/life balance, China is joined by countries like Portugal and Canada in enacting new policies protecting pandemic-era workers as health (physical, mental, and emotional) and happiness are a greater priority than ever before. People are seeking balance in new-found acceptance rather than stereotypical achievement.</p>
        
        <p>Considering today’s pressing tensions, brands will need to depart from traditional marketing methods that instill consumer’s desire for status. Rather than stoking aspirations, brands will need to meet the modern consumer where they are and deliver value in newly relevant ways. They’ll need to flip the script from traditional consumption models toward more sustainable means of business that empower both the consumer and the planet. </p>
        
        `
    },
    SECTION_2: {
        CATEGORIE: "NEW OPPORTUNITIES",
        CONTENT: [
            {
                HEADER_1:"GIVE vs",
                HEADER_2:"take",
                IMAGE: "group-411@3x.png",
                COPY: "Take a breath, a pause. Have a moment. Today, we all need the headspace to meander, to have a bit of a mental recess. Brands have historically been wired to capture what’s been termed “mindshare,” but what if they gave mental space instead? ",
                SUB_COPY: `SOOTHING SOLACE<br> No heightened sense of urgency, no hype, no hyperbole. Relevant brands in this space understand the context within which they are doing business. As such, they speak with a modern, empathetic tone of voice - offering an edifying space safe from the complexity and noise of the everyday.  `
            }
        ]
    },
    SECTION_3: {
        CATEGORIE: "NEW CONSUMERS",
        CONTENT: [
            {
                HEADER_1:"Empath",
                HEADER_2:"the modern",
                IMAGE: "mask-group-5@3x.png",
                COPY: "A newly informed sense of self-care focuses on the “we” as much as the “me.” This consumer is attuned to the struggles of others and a brand’s role in either the systems of privilege, access, and social responsibility.",
                SUB_COPY: `SUSTAINABLE SELF<br>Reoriented in terms of how success and life’s purpose is defined, brands will need to learn to speak to a consumer with a more balanced, global view of successes and achievement. Mental and emotional health will take unprecedented priority.`
            }
        ]
    },
    SECTION_4: {
        CATEGORIE: "NEW CAPABILITIES",
        CONTENT: [
            {
                HEADER_1:"CLASSICS",
                HEADER_2:"evolved",
                IMAGE: "self-stasis-classics@3x.png",
                COPY: "Delivering value without the use of product tiering, requires an element of classicism. Enduring quality is so valuable that it can stretch its worth across sectors from utility to luxury effortlessly.",
                SUB_COPY: `RECONTEXTUALIZED CONVENIENCE<br>
                Expect newly relevant convenience platforms. Imagine the convenience products of the early 1950’s targeted at time-starved households. This time, platforms will offer efficiency without compromising quality or sustainability. `
            }
        ],
    },

}

export const RATIONAL_RESET = {
    SECTION_1: {
        TITLE_1: "Reset",
        TITLE_2: "Rational",
        HERO: "rr_hero.png",
        SUBTITLE:"The shift from A STATE OF EXISTENTIAL CRISIS to the solace of structure, reason, and metaphysical truths.",
        CALLOUT_IMG: "rr_callout.png", 
        CALLOUT_HEADER: "in 2021", 
        CALLOUT_BLURB: "E-book sales of Letters from a <i>Stoic</i> went up 356% in 2021",
        CATEGORIE: "",
        CONTENT_1:`
        <p>
        These are resentful times. It’s a noisy world of polarization and protests, extremists and conspiracy theorists, the woke and the anti-woke, and hyperbole both from the far-right and far-left. In the face of chaos, many are looking to the one aspect of their lives that is seemingly manageable. In a word, reason. </p>

        <p>Philosophies of self-control are finding new relevance and are being adopted across social platforms in newly modern and meaningful ways. A modern take on Stoicism (termed Pop Stoicism) has been quietly gaining momentum for years now. If 2021 book sales are any indication of in this shift in sentiment, e-book sales of Letters from a Stoic went up 356% and Ryan Holiday’s Courage is Calling (a book describing the stoic concept of harnessing all that has happened as fuel to face what is next) was an instant <i>New York Times, Wall Street Journal</i>, and <i>USA Today</i> Bestseller. In contrast to a culture of extremes and polarities, the logic of stoic philosophies teach the virtue in becoming a clear and unbiased thinker as a means to understand universal reason. </p>`,
        CONTENT_2:
        `
        <h4>MEDITATIONS ON MATERIALITY</h4>
        <p>This revived interest in stoic philosophies come at an interesting time - when both capitalism and conspicuous consumption (buying to display wealth) have been called into question. Well known stoic philosopher Marcus Aurelius chose to see consumable goods apart from the aspects that made them objects of desire. “When we have meat before us and other food, we must say to ourselves, “This is the dead body of a fish, a bird or of a pig,… this purple robe some sheep’s wool died with the blood of a shellfish” … so that we see what kinds of things they are.” </p>
        
        <p>Given his objective material view, how would otherwise intangible values of status, provenance, and artisanship fair in the eyes of a modern-day stoic? At face value, Stoicism seems incompatible with capitalism. This is largely because of the tenants that reject materialism in favor of the pursuit of virtue. However, Aurelus’ view might suggest that the philosophy underpins an already growing shift toward sustainable and purpose-driven brands, the rise of the secondhand economy, collective consumerism, and emerging grey economies.  </p>
        <p>If we look to a stoic’s views on the shortness of life, it marries well with a shift from ownership toward rentalism and placing value on timeless materiality. In fact, this mindset could arguably reframe modern notions of luxury. In a recent Adam Magazine perspective piece from one of the most highly respected trend practitioners in the industry, Lidewij Edelkoort articulated her outlook on emerging materiality and its relationship to the future of luxury when she said “The new luxury will be extremely comfortable and sustainable, but beautifully made.” See? Capitalism does have a place in Stoicism - if the sales made are thoughtful and serve a higher purpose. </p>

        <h4>SHIFTING SENTIMENT</h4>
        <p>From high culture to pop culture, manifestations of this shift in sentiment are evident on the landscape. Fashion, a lightning rod for emerging shifts in the zeitgeists, is often an early soft signal of evolving sentiment. So, it was thematically notable when Burberry launched its 2021Olympia Bag with its “World of Olympia” pop-up in Harrod’s. Pointing to metamorphosis as his inspiration, designer Ricardo Tisci chose to feature an AR-animated statue of Elpis as a central figure. Fittingly, Elpis is the Greek spirit of hope, and the last to escape Pandora’s box. </p>
        
        <p>TikTok – the new litmus of pop culture – is observing a surge of Stoic content where references to figures like Markus Aurelius live alongside the phenomenon that is Khabane Lame. Both seem to present a calm objectivity, a rational take on ridiculousness. Lame - the most popular man on TikTok -  has achieved that status without speaking a word. Widely recognized by his content that mocks peer’s bait-click posts with his deadpan expression and signature head shake or silent shrug, Lame’s wild popularity is an indication that many are seeking both comedy and comfort in his objective, common-sense response to absurdity. Ultimately, it seems we’re all looking for a well-deserved reality check. </p>
        
        <p>This shift demonstrates an aspiration for assurances of structure, reason, and enduring truths. Brands activating in this territory will need to answer to an evolved expectation for a very pragmatic articulation of value - doubling down in many cases to truly deliver on buzzwords like “transparency” and “authenticity.” This consumer wants more substance, less story. </p>
        
            
        `
    },
    SECTION_2: {
        CATEGORIE: "NEW OPPORTUNITIES",

        CONTENT: [
            // {
            //     HEADER_1:"SELF-CARE",
            //     HEADER_2:"pragmatic",
            //     IMAGE: "rr-img-woman-dog@3x.png",
            //     COPY: "More science - less smoke and mirrors. An evolved take on self-care borrows from humanistic psychology, focusing on the needs of the individual’s personal path toward a healthier state of being. It’s a you-do-you methodology that is both approachable and applicable to an average person’s wellness journey. ",
            //     SUB_COPY: `ESSENTIAL EXPERIENCES<br> On the heels of fusion, fiery profiles, and flavor-tripping, let’s take a pause, pare back, and imagine flavor and even fragrance profiles that are singular, simplified and distinctive. This is a shift toward fundamental approach to formulation that invites nuance, austerity, and minimalism.`
            // },
            {
                HEADER_1:"PERSONALIZATION",
                HEADER_2:"practical",
                IMAGE: "group-342@3x.png",
                COPY: "If the explosion of the personalized supplements category is a signpost of sorts, it’s time for just what you need and nothing you don’t. The recent proliferation of at-home-testing could usher in a new era of nutrition and behavioral science impacting everything from skincare to mental health.",
                SUB_COPY: ``
            }
        ]
    },
    SECTION_3: {
        CATEGORIE: "NEW CONSUMERS",
        CONTENT: [
            {
                HEADER_1:"OBJECTOR",
                HEADER_2:"conscientious",
                IMAGE: "rational-reset-conciencious-objector@3x.png",
                COPY: "This consumer sees brands, especially large ones, through a lens of skepticism and bad faith. They need to be bought into a clear and relevant purpose that has no gaps or missing links in the virtuous cycle. Because when they are surrounded by big brands that compromise their well-being, they disengage from most marketing and brand loyalty. Their affinity to brands is rare and well-earned. ",
                SUB_COPY: `FACT-LED FUNDAMENTALIST<br>
                 The education driven consumer wants knowledge not nudging. Resistant to typical marketing tactics, they seek credible information needed to make an informed choice. They respond to transparent, realistic benefits, and credible reasons to believe—rather than hype and cleverness that obscures their clear view of the proposition.`
            }
        ]
    },
    SECTION_4: {
        CATEGORIE: "NEW CAPABILITIES",
        CONTENT: [
            {
                HEADER_1:"MYTH-BUSTING",
                HEADER_2:"brands",
                IMAGE: "magnifing-glass@3x.png",
                COPY: "The fact seeking consumer is on a mission to uncover the truth. Brands that pull back the curtain and challenge conventionalities will endear this cohort. Consider reframing the nutrition pyramid, applying science to self-care, or bringing radical transparency to retail. ",
                SUB_COPY: `EDUTAINMENT<br> The necessity of distanced learning opened a new genre for knowledge-seeking consumers. From upskilling to curated inspiration, edutainment is still maturing as a new means of speaking across platforms, and a meaningful place for brands to intersect with consumers. `
            }
        ],
        CITATION: `<p class="citation">References: Interest in centuries-old Stoicism, navigating uncertainty through a discipline of mitigating assumptions and identifying those issues that are beyond your influence.  

        https://www.refinery29.com/en-gb/2021/01/10113753/stoicism-mindfullness-how-to-build-resilience <br>
        
        Print and E-Book Sales The Guardian https://www.vice.com/en/article/xgxvmw/the-revival-of-stoicism <br>
        
         On “Pop Stoicism” Coopting an Ancient Philosophy to justify selfishness and devalue emotion. https://medium.com/publishous/where-pop-stoicism-misses-the-mark-978d823f29a5 <br>
        
        Mainstream Stoicism https://www.realsimple.com/health/mind-mood/emotional-health/what-is-stoicism <br>
        
        https://www.newyorker.com/magazine/2021/08/23/why-is-it-so-hard-to-be-rational <br>
        
        In The New York Times’ review of Stephen Pinker’s 2021 book “Rationality: What it is, Why It Seems Scarce and Why It Matters,”the author speaks to rationality’s bad rap. “Rationality is uncool…It isn’t seen as “dope, phat, chill, fly, sick or da bomb.” https://www.nytimes.com/2021/09/29/books/review-rationality-steven-pinker.html	 
        </p>
        `
    }
}

export const VIVA_IRL = {
    SECTION_1: {
        TITLE_1: "IRL",
        TITLE_2: "Viva",
        HERO: "vi_hero.png",
        SUBTITLE:"The shift from virtual, performative happiness to a yearning for visceral, human pleasure.",
        CALLOUT_IMG: "vivairl-43-bn.png", 
        CALLOUT_HEADER: "discussions of sex toys", 
        CALLOUT_BLURB: "Over the last 12 months, people discussed sex toys 4.3 billion times—a YOY increase of 4595%. -Marks’ Social Listening Data",
        CONTENT_1:
        `
        <p>Hedonism - it’s having a moment. Looking back, it’s easy to recognize Western societies’ “Roaring Twenties” as a reaction to the trauma of World War One and the 1918 flu epidemic. Today, it seems our collective, creative psyche might be experiencing similar pangs. We are experiencing a longing for fundamental, human connection and deep, intense sensory experiences—a yearning for the visceral rather than the virtual. </p>

        
        
        `,
        CONTENT_2: `
        <h4>SEXUAL HEALING</h4>
        <p>The pandemic pivoted our views on sexual wellness and pleasure-seeking extravagance is emerging. There was an 4595% uptick in the digital discussion of “sex toys” in the past year alone. While the sales of condoms plummeted, the sales of sex toys soared. The global sexual wellness market size is expected to reach $125.1 billion by 2026, with a growth rate forecasted at 12.4% CAGR, according to BusinessWire. Life in lockdown gave lift to new revenue streams like adult-only content platforms such as OnlyFans. Some reports claim the brand is now seen as one of the fastest-growing social media platforms with as many as 170 million subscribers to date. At the intersection of the gig economy and amateur erotica, we find an example of once-risqué content moving to the mainstream. </p>
        
        <p>Sexual desires in partnerships are changing too. A 2020 Bumble survey found that 14% of respondents reported a shift in their sexual preferences, pondering unfulfilled desires and even sexual orientation. In Spring of 2021, The Kinsey Institute partnered with Cosmopolitan and Esquire Magazines to study 2,000 Americans and the impact of COVID-19 on their sex lives. 46% said they were engaging in more sexual experimentation, and 19% said they would be more inclined to pursue an open relationship in the future. This pre-pandemic trend of non-monogamy is bleeding into the mainstream much faster than expected. And when it comes to polyamory, a Rolling Stone report found that 4-5% of people in the United States practice, and roughly 20% reported being in a poly relationship in their lives. Chatter is up on subreddits as well with an exponentially growing commentary of 230K+. The take? What was once considered shock-value entertainment (think TLC’s Sister Wives) now has a much more substantial cultural relevance. </p> 
        
        <p>The meteoric rise in the popularity of members-only sex clubs from the UK to NYC suggests there are a growing number of people in search for hedonic healing. In 2021, Journalist Avery Stone wrote a piece for The Cut chronicling her experience with the clubs and explained that both partiers and employees suggested an unprecedented influx of newbies. SNCTM (sanctum), an exclusive Beverly Hills-based sex club with a global presence reopened in the Spring of 2021 with tickets ranging from $500 to $8,000. The self-proclaimed ‘world’s most elite sex club’ warned “tickets are extremely limited.”</p>
        
        <h4>A TRIPPY TRAJECTORY</h4>
        <p>If there was such a thing as a trippy tipping point, Michael Pollan’s 2018 #1 New York Times best seller “How to Change Your Mind: What the Science of Psychedelics Teaches Us About Consciousness, Dying, Addiction, Depression and Transcendence”, may have served as such, initiating a psychedelic renaissance of sorts. In 2021, psychedelic therapies moved to the fore, becoming a psychedelic renaissance of sorts. Data from the 2021 Global Drug Survey - which claims to be the largest drug survey in the world - reported that a large amount of people are getting “a little high” through microdosing psychedelics for therapeutic purposes. For many, it’s not about the high, rather, it’s about connecting with something bigger - accessing aspects of what it means to be human in ways that aren’t normally attainable. Cultural activist and psychedelic feminist, Zoe Helene told DAZED, “Psychedelics … have great potential to show us the humanity in each other, and to understand our unequal positioning.” It’s a renaissance needed in times like these.</p>
        
        <p>TripTok, TikTok’s emerging, trippy subculture features consciousness-enhancing content, and has been viewed over 13M times. If we look to the cannabis industry, a once-risqué industry now established as one of the world’s biggest investment opportunities, we can anticipate similar adoption and speculation related to psychedelic substances. In fact, Fortune Magazine projects the market to reach $10.75 billion by 2027. Psilocybin’s promise as a realistic alternative to antidepressants with a lower rate of post-treatment relapse is capturing both investors’ attention and capital.</p>
        
        <p>Following the shift toward recreational wellness and the popularity of cannabis tourism, psychedelic tourism is quietly gaining momentum. If travel and transcendence is your jam, consider Silo Wellness, a new breed of women and LGBTQ+ focused health and wellness retreats. Guests journey to a beachside villa in Jamaica to take part in a legal, mushroom-enhanced respite. While such retreats have been around for almost a decade, today’s conditions lend them more relevance. The runway for these guided, extended experiences with hallucinogens has been greased by the mainstreaming of cannabis. For all the risks and unknowns related to psychedelic substances, there seems to be a common theme among these tourists (who report real life-changing behavioral and mental changes), they’re in it for the bigger picture. </p>
        
        <p>In Denver, metaverses may find formidable competition IRL. At the intersection of art and entertainment exists what some might call a psychedelic playground of sorts. The city’s latest immersive, substance-enhanced experience is designed by Meow Wolf, creators of sensory, mind-bending art installations. Visitors explore over 70 trippy, sensorial experiences including kaleidoscopic cathedrals, technicolor alien habitats and dreamscaped catacombs—all intended to extend audiences’ imaginations.</p>
        
        <p>This shift toward experiences that offer visceral, human pleasure is all about maximizing the sensuality of the here and now. Brands activating on this shift will need to speak to a consumer with evolved tastes, a consumer seeking to throw caution to the wind and experience life to its fullest. Premium product positioning will give permission to move otherwise taboo products, platforms, and services out of the fringe. Relevant activations will require brands to be bold, provocative, and more sensuously engaging than ever before.</p>
        
        `
    },
    SECTION_2: {
        CATEGORIE: "NEW OPPORTUNITIES",
        CONTENT: [
            {
                HEADER_1:"SELF-CARE",
                HEADER_2:"shamanic",
                IMAGE: "group-426@3x.png",
                COPY: "Forget billionaire astronauts, and the metaverse. Bring in the psychonaughts of the subverse, a new breed of psychedelic healing that is making it’s way to the forefront of overall wellness. Welcome to the verge of what PsyTech calls an emerging frontier for “accelerated category innovation.”",
                SUB_COPY: `SENSORY-EXTENDED ENTERTAINMENT<BR> The bar for the offline retail experience is now exponentially higher which presents massive opportunity for brands with the creative muscle to infuse meaning, entertainment, and true human connection into retail. Brands will need to access a broader toolbox.<br><br>
                MAINSTREAMING SEXUAL WELLNESS<br>
                Sexual wellness has become more aligned with beauty at a time when beauty sits as a subset of the larger wellness category. As such, sexual wellness is shedding taboos and taking on premium appeal. Consider this the new frontier of premium products and platforms. 
                `
            }
        ]
    },
    SECTION_3: {
        CATEGORIE: "NEW CONSUMERS",
        CONTENT: [
            {
                HEADER_1:"PHOENIXES",
                HEADER_2:"female",
                IMAGE: "group-412@3x.png",
                COPY: "Accessing the restorative powers of psychedelic therapies, new voices are empowering a diversified following, exploring meaningful issues like body positivity and patriarchy. The future of psychedelics is feminist. For this cohort of women, leadership isn’t a matter of reinvention - it’s a matter of rebirth",
                SUB_COPY: `GUIDED GASTRONOMES<BR> According to <i>The Globe and Mail</i>, grocers are investing in the guided shopping experience for a cohort of post-pandemic foodies. Grocers are seizing the opportunity to uptrain staff to partner with shoppers, more firmly reframing shopping brick-and-mortar as a food exploration experience.<br><br>
                THE EMERGING EPICURE<br> There’s a newfound appreciation for both the extraordinary and the straightforward. This evolved connoisseurship understands life is short, and more than ever, they’re finding indulgence in intensity and excellence rather than pretense. 
                `
            }
        ]
    },
    SECTION_4: {
        CATEGORIE: "NEW CAPABILITIES",
        CONTENT: [
            {
                HEADER_1:"RETAIL",
                HEADER_2:"try-curious",
                IMAGE: "vivairl-try-retail@2x.png",
                COPY: "Touch, taste and trial must be reinvented to be newly accessible in retail’s post-pandemic normal. This doesn’t mean brands need to speak louder. It means brands must speak smarter with a broader communication toolbox that can extend their ethos into the crux of culture. ",
                SUB_COPY: `REIMAGINED TECHNOLOGIES<BR> Confinement has fueled a deep yearning for travel, exploration and the unexpected. AR and VR aside, there is an emerging opportunity to extend some of the most accessible, current technologies like QR and Livestream to connect consumers with new communities and expansive brand experiences. `
            }
        ]

    }
}

export const EDITOR_LETTER = {
    SECTION_1: {
        TITLE_1: "Editor's",
        TITLE_2: "Letter",
        HERO: "el_hero.png",
        SUBTITLE:"WTF: Why Think Forward",
        SUBTITLE_2:"These three themes shape the world that we all will live in for the next year and each has associated new consumers, new opportunities, and new capabilities.",
        CALLOUT_IMG: "el_callout.png", 
        CALLOUT_IMG2: "el_callout.png",
        CALLOUT_HEADER: "future",
        CALLOUT_HEADER2: "fatigued",
        CALLOUT_BLURB: "According to The American Psychology Association, 32% of adults are so stressed they struggle to make everyday decisions like what to wear and when to eat.",
        CALLOUT2_BLURB: "This newfound state of No Future is, in my opinion, a very good thing … It indicates a kind of maturity, an understanding that every future is someone else’s past, every present is someone else’s future.",
        CONTENT_1: `
        <p>The opening line in William Gibson’s 1984 debut novel “Neuromancer” reads “The sky above the port was the color of television, tuned to a dead channel.” For those unfamiliar, it wasn’t blue like today’s dead screens–rather it was a black and white static of pre-pixelated grey. The irony here is that for Gibson’s prescient ability in this book to depict any number of things, including the rise of the internet, cyberspace, AI, and yes even cyber cowboys—his opening line was still anchored in what is now a memory of the past. </p>

        <p>Still, that static sky is an apropos image when reflecting on 2021, a year in hangover from the existential juggernaut, the dumpster fire that was 2020 - the year the future was canceled. Our collective breath held, we bare knuckled through to see if there was a light at the end of the tunnel. As vaccines rolled out, many imagined Heteronymous Bosch-style return to touch, taste, travel, and tangibility. Aspirations soon gaslit by conspiracy theories, variants, and more stay-at-home orders.  

        Disillusioned, we ask ourselves WTF. <i>Why Think Forward?</i> </p>
        `,
        CONTENT_2: `
        <p>Half of adults feel that planning for the future is impossible, and it only seems to be increasing over time.</p>
        <p>In a 2020 BBC interview Gibson characterized our post-modern nihilism as “future fatigued.” Will we prevail against human inequities, radically curb our poisoning of the planet, or suddenly find real empathy for the disenfranchised? It seems unlikely.  </p>
        <p>With these pains still present, it’s logical to ask why anyone would look to the future.  </p>
        `,
        CONTENT_3:
        `
        <p>Well, take solace in how Gibson finished his interview “This newfound state of No Future is, in my opinion, a very good thing … It indicates a kind of maturity, an understanding that every future is someone else’s past, every present is someone else’s future.”[sic]  A wise perspective from a man with imaginative perceptions of the future and its potential - despite all its baggage. In this pivotal moment, we must release the painful past alongside an unhealthy fear of the future and take stock of what’s now.</p>
        
        <p>In taking said stock, we have articulated three key shifts in response to this crisis that can prepare us to navigate the future. Author, Damian Barr captured the precariousness of our time in a single viral tweet “We are not all in the same boat, but we are in the same storm. Some are on super-yachts. Some have just one oar.” This tweet turned viral signals the significance that inequity plays in the shared experiences of today.  These shared experiences define a generation, and typically unfold over decades. Today, we’ve experienced a complexity of crisis in a just few years. These extreme conditions have instigated intensely human responses. Unpacking these three shifts, we hope to better understand the shifting values, attitudes, and behaviors of today’s consumers. In this, we find a more mindful, productive way of thinking forward. </p>
        <br><br>
        `
    }
}