import React from "react";
import "./header.scss";
import hamburger from "./../../../assets/core/hamburger.svg";
import logo from "./../../../assets/core/logo.svg"
import logoSmall from "./../../../assets/core/wtf-group.svg"
import logoMarks from "./../../../assets/core/marks-logo.svg"

const Header = ({ onClick, isSubPageOpen, isNavBarHidden, setSlide, closeSubPage, setIsNavBarHidden }) => {

    const handleMenu = (i) => {
        const wheelEvt = document.createEvent('MouseEvents');
        const scrollContainer = document.querySelector(".scrollContainer");
        const el_El = document.querySelector(".pillar.editors-letter");
        const titleEls = document.querySelectorAll(".pillar__text");

        wheelEvt.initEvent('wheel', true, true);
        wheelEvt.deltaY = ((i-1) * 0.2) * scrollContainer.scrollWidth
              

        const parallaxEffect = (e) => {
      
            //   scrollContainer.scrollLeft += 0.07 * e.deltaY
              scrollContainer.scrollLeft = ((i-1) * 0.2) * scrollContainer.scrollWidth

      
            const percentage =
              (scrollContainer.scrollLeft / scrollContainer.scrollWidth) *
              100;
      
            // This if for the Parallax Effect for the collages on Desktop
            el_El.style.backgroundPosition = `
            calc(70% + 5vw) center, 
            calc(${percentage * -5 + 330}%) calc(50%)
          `;
      
            // This if for the Parallax Effect for the Titles on Desktop
            for (let [i, title] of titleEls.entries()) {
              title.style.transform = `translate3d(${scrollContainer.scrollLeft / -5
                }px, 0, 0)`;
            }
          };

          parallaxEffect()
        
        setSlide(i-1)
        // console.log(closeSubPage);
        document.querySelector(".subpage").scrollTo({top: 0,left: 0,behavior: 'smooth'});
        if(isSubPageOpen)closeSubPage()
        if(isNavBarHidden)setIsNavBarHidden(false)
    }

    return (
        <div className={`header ${(isNavBarHidden) ? "sticky-nav" : ""} ${(isSubPageOpen) ? "white" : ""}`}>
            <div className="header__logo">
                <a href="#editors-letter" onClick={() => handleMenu(0)} >
                    <img src={(isNavBarHidden)? logoSmall : logo} />
                </a>
            </div>
            {(isNavBarHidden) ? 
              <div className="header__logo-marks">
                <img src={logoMarks} alt="marks" />
              </div>
               : ""}
            <button className="header__menu" onClick={() => onClick()}>
                <img src={hamburger} />
                Menu
            </button>
        </div>
    );
};

export default Header;
