import React, { Fragment } from "react";
// import Image from "./../../../assets/images/";
const SubPageBody = ({data}) => {

    return (
        Object.keys([data]).map((currentSection, i) => (
            <Fragment key={i}>
                <div className="subpage__divider"></div>
                <p className='section__header'>{data[currentSection].CATEGORIE}</p> 
                {Object.keys(data[currentSection].CONTENT).map((key, i) => (
                    <div className="grid-wrapper" key={i}>
                        <div className="grid-inner">
                            <img className="subpage-section-image--desktop" src={require(`../../../assets/images/${data[currentSection].CONTENT[key].IMAGE}`)} alt={data[currentSection].CONTENT[key].HEADER_1}/>
                        </div>
                        <div className="grid-inner">
                            <div className="header-wrapper">
                                <h2>{data[currentSection].CONTENT[key].HEADER_1}<span>{data[currentSection].CONTENT[key].HEADER_2}</span></h2>
                            </div>
                            <img className="subpage-section-image--mobile" src={require(`../../../assets/images/${data[currentSection].CONTENT[key].IMAGE}`)} alt={data[currentSection].CONTENT[key].HEADER_1}/>

                            <div className="copy__wrapper">
                                <p dangerouslySetInnerHTML={{ __html: data[currentSection].CONTENT[key].COPY }} />
                                <p dangerouslySetInnerHTML={{ __html: data[currentSection].CONTENT[key].SUB_COPY }} />
                            </div>
                        </div>
                    </div>
                ))}         
                {
                    (data[currentSection].CITATION) ? <p dangerouslySetInnerHTML={{ __html: data[currentSection].CITATION }} /> : ""
                }
            </Fragment>
        ))
    )
}

export default SubPageBody
